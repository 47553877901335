'use client'; // Error components must be Client Components

import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  const t = useTranslations('ErrorPage');
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error);
    console.error(error);
  }, [error]);

  return (
    <div
      className="hero min-h-screen"
      style={{ backgroundImage: 'url(/images/error-robot.webp)' }}
    >
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md space-y-5">
          <h1 className="text-5xl font-bold">{t('something-went-wrong')}</h1>
          <h6 className="text-2xl font-bold md:text-3xl">
            {t('show-our-engineer-the-message')}
          </h6>
          <p className="text-error md:text-lg">{error?.message}</p>
          <button
            className="btn btn-primary"
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            {t('try-again')}
          </button>
        </div>
      </div>
    </div>
  );
}
